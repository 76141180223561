.search-partner-page .container{
    max-width: 1200px;
    margin: 0 auto;
}
.search-partner-page {
    padding-top: 120px;
    padding-bottom: 50px;
}
.search-partner-page .field-div{
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
}
.age-row {
    display: flex;

}
.search-partner-page .field-div label{
    display: block;
    margin-bottom: 5px;
    width: 20%;
}
.search-partner-page .age-field{
    width: 100% !important;
}
.age-sp{
    padding: 10px;
}

.search-partner-page input[type="text"], .search-partner-page input[type="number"], .search-partner-page select, .search-partner-page button {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 50%;
}
.search-partner-page input[type="radio"], .search-partner-page input[type="checkbox"] {
    margin: 0px 7px;
}
.search-partner-page .checkbox-group {
    display: flex;
    align-items: center;
    width: 20% !important;
}
.search-partner-page .field-submit-div{
    text-align: end;
    width: 42%;
}
.search-partner-page .field-submit-div button{
    color: #fff;
    background: #00bcd5;
    border: 1px solid #00bcd5;
    border-radius: 3px;
    /* padding: 5px 0px 7px 0px; */
}
.search-partner-page .error{
    color: red;
    font-size: 14px;
    padding: 0px 10px;

}
@media (max-width: 768px) {
    .search-partner-page .field-div {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .search-partner-page .field-div label {
        width: 100%;
        margin-bottom: 5px;
    }

    .search-partner-page input[type="text"],
    .search-partner-page input[type="number"],
    .search-partner-page select,
    .search-partner-page button {
        width: 100%;
    }

    .search-partner-page .checkbox-group {
        width: 100%;
    }

    .search-partner-page .field-submit-div {
        width: 100%;
        text-align: center;
    }

    .search-partner-page .field-div label + div,
    .search-partner-page .field-div label + span + div {
        width: 100%;
    }

    .search-partner-page .field-div.age-field {
        flex-direction: column;
        width: 100%;
    }

    .search-partner-page .field-div.age-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .search-partner-page .age-field {
        width: 40%;
    }

    .search-partner-page .field-div.gender-field,
    .search-partner-page .field-div.children-field,
    .search-partner-page .field-div.have-children-field {
        flex-direction: column;
        align-items: flex-start;
    }

    .search-partner-page .field-div.gender-field .gender-options,
    .search-partner-page .field-div.children-field .children-options,
    .search-partner-page .field-div.have-children-field .have-children-options {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .search-partner-page .field-div.gender-field .gender-options label,
    .search-partner-page .field-div.children-field .children-options label,
    .search-partner-page .field-div.have-children-field .have-children-options label {
        margin-right: 15px;
        margin-left: 5px;
    }
}