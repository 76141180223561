.profile-section {
  font-family: 'DM Sans', sans-serif;
  padding: 20px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff;
}

.profile-sub-heading {
  font-size: 2rem;
}

.progress-bar {
  display: flex;
  flex-direction: row !important;
  width: 1300px;
}

.progress-bar div {
  width: 145px;
  /* Divide the progress bar into equal sections for 4 steps */
  padding: 3px;
  margin-right: 32px;
  border-radius: 5px;
  background-color: #ccc;
  /* Set default background color for progress bar items */
}

.progress-bar .current {
  background-color: #000;
  /* Black color for active progress bar item */
}

.page {
  width: 100%;
  /* Form width set to 50% of the parent container */
  max-width: 1300px;
  /* Maximum width of the form */
  box-sizing: border-box;
}

.page h2 {
  margin-bottom: 20px;
  margin-top: 90px;
  font-family: 'DM Sans', sans-serif;
  font-size: 3.125rem;
}

.profile-form {
  margin-top: 100px;
}

.profile-name {
  margin-top: 60px;
}

.profile-space {
  margin-top: 90px;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 2.5rem;
  margin-bottom: 25px;
  font-weight: 500;
  line-height: 52.08px;
}

.form-group input,
.form-group select {
  padding: 8px;
  /* Reduced padding */
  font-size: 1.25rem;
  /* Reduced font size */
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 9.4rem;
}

.input-space {
  margin-bottom: 40px !important;
  width: 600px !important;
}

.row {
  display: flex;
  gap: 10px;
}

.profile-buttons {
  display: flex;
  flex-wrap: wrap;
}

.profile-button {
  font-family: 'Playfair Display', serif;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  color: #000000;
  cursor: pointer;
  font-size: 1.25rem;
  margin: 5px;
  min-width: 9.4rem;
  max-width: 14.4rem;
}

.profile-button.selected {
  background-color: #FF4D6A;
  color: #fff;
}

.next-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #6DD6D6;
  color: #fff;
  display: block;
  margin: 0 auto;
  width: 472px;
  margin-top: 65px;
  font-size: 1.5rem;
}

button.finish-button {
  background-color: #4CAF50;
}

button+button {
  margin-left: 10px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .profile-section {
    padding: 30px;
    padding-top: 50px;
  }

  .profile-form {
    margin-top: 10px;
  }

  .profile-sub-heading {
    font-size: 0.9rem;
    /* Adjusted font size */
  }

  .progress-bar {
    flex-direction: column;
    width: 90%;
  }

  .progress-bar div {
    width: 90%;
    margin-right: 10px;
    margin-bottom: 16px;
  }

  .page h2 {
    font-size: 1.5rem;
    margin-top: 30px;
    /* Reduced margin */
  }



  .profile-button {
    font-size: 0.9rem;
    /* Adjusted font size */
    min-width: auto;
    max-width: 90%;
    margin: 5px 5px;
    padding: 5px 18px;
  }

  .next-button {
    width: 90%;
    font-size: 1.25rem;
    margin-top: 20px;
  }

  .profile-name {
    margin-top: 20px;
  }

  .profile-space {
    margin-top: 1px;
  }

  .profile-form.step-1 .form-group {

    label {
      font-size: 1.5rem;
      margin-bottom: 5px;
      font-weight: 500;
      line-height: 52.08px;
    }

    input {
      font-size: 1rem;
      /* Adjusted font size */
      max-width: 90%;
    }

    .input-space {
      margin-bottom: 10px !important;
      width: 600px !important;
    }


  }


  .profile-form.step-2 .form-group {
    label {
      font-size: 1.5rem;
      margin-bottom: 5px;
      font-weight: 500;
      line-height: 52.08px;
    }

    input {
      font-size: 1rem;
      /* Adjusted font size */
      max-width: 90%;
    }

    select {
      font-size: 1rem;
      max-width: 90%;
      margin: 0px;
    }

    .input-space {
      margin-bottom: 10px !important;
      width: 600px !important;
    }


  }


  .profile-form.step-3 .form-group {
    label {
      font-size: 1.5rem;
      margin-bottom: 5px;
      font-weight: 500;
      line-height: 52.08px;
    }

    input {
      font-size: 1rem;
      /* Adjusted font size */
      max-width: 90%;
    }

    .input-space {
      margin-bottom: 10px !important;
      width: 600px !important;
    }
  }
}