.missuse-container {
  background: #f1f1f2 !important;
  min-height: 100vh;
  width: 100%;
  padding: 150px 30px;
}

.missuse-container form {
  width: 100%;
  box-shadow: none;
  position: relative;
  padding: 10px 40px;
  background-color: #fff;
  border-right: 1px solid #dadfe3;
  border-radius: 6px;
}
.form-label {
  margin-bottom: 0 !important;
  margin-right: 20px;
  width: 150px;
  font-size: 14px;
  vertical-align: middle;
  color: #666;
}
.input-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 21.875px;
}
.header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left-text {
    font-size: 18px;
    font-weight: 600;
    margin: 16px 0 26px 0;
    color: #183247;
}
.go-back-text {
    color: #72727d; 
    font-size: 12px;
    font-weight: lighter;
    line-height: 1.5;
    float: right;
        margin-bottom: 20px;
        padding: 20px 15px 0 18px;
        text-align: right;
        vertical-align: middle;
        width: 208px;
}
.highlight-text {
    color: #00bcd5;
}
.color-dot{
    color: red;
}
.form-control-sm {
    padding: 0 10px !important;
    
}
.react-quill {
    width: 100%;
}
.ql-editor {
    min-height: 100px !important;
}
#attachFileBtn{
    margin-left: 12%;
    margin-bottom: 21.875px;
    color: #1272de;
    font-size: 14px;
}
#selectedFiles {
    margin-left: 12%;
    margin-bottom: 21.875px;
    font-size: 14px;
}


.form-actions {
    padding-left: 160px;
    margin-top: 10.9375px;
    padding-top: 10.9375px;
    margin-bottom: 21.875px;
    border-top: 1px solid #e5e5e5;
}
#helpdesk_ticket_submit{
    width: auto;
    font-family: Poppins, Helvetica, sans-serif;
    border-radius: 2px;
    background-color: #00bcd5;
    border: solid 1px #00626f;
    background-image: none;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-shadow: none;
    text-transform: uppercase;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 10px 25px;
    color: #fff;
}
.cancel-btn {
    color: #183247;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: 1.2px;
    text-shadow: none;
    text-transform: uppercase;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    background-color: #f3f5f7 !important;
    border: 1px solid #97a9ba !important;
    padding: 10px 25px !important;
    margin-left: 10px;
    border-radius: 0 !important;
}
@media (max-width: 768px) {
    .missuse-container {
        background: #f1f1f2 !important;
        min-height: 100vh;
        width: 100%;
        padding: 120px 15px;
        height: 850px;
    }
    .missuse-container form {
        width: 100%;
        box-shadow: none;
        position: relative;
        padding: 5px 15px;
        background-color: #fff;
        border-right: 1px solid #dadfe3;
        border-radius: 6px;
    }
    .left-text {
        font-size: 16px;
        font-weight: 600;
        margin: 16px 0 26px 0;
        color: #183247;
    }
    .input-box {
      flex-direction: column; /* Stacking input elements vertically on smaller screens */
      width: 100%; /* Ensuring input boxes cover full width */
      margin-bottom: 15px; /* Adjusted margin for smaller screens */
    }
  
    .form-control {
      width: 100%; /* Making form controls cover full width */
    }
  
    .form-label {
      width: 100%; /* Making labels cover full width */
      margin-right: 0; /* Removing right margin for smaller screens */
      margin-bottom: 5px; /* Adjusted margin for smaller screens */
    }
  
    .header-box {
      flex-direction: row; /* Stacking header elements vertically on smaller screens */
    }
  
    .go-back-text {
      float: none; /* Removing float for smaller screens */
      text-align: center; /* Centering text on smaller screens */
      width: auto; /* Allowing text to take natural width */
      margin-bottom: 30px; /* Adjusted margin for smaller screens */
    }
  
    #attachFileBtn,
    #selectedFiles {
      margin-left: auto; /* Aligning attachment elements to the right on smaller screens */
    }
  
    .form-actions {
        display: flex;
      padding-left: 0; /* Removing left padding for smaller screens */
      text-align: center; /* Centering actions on smaller screens */
    }
  
    #helpdesk_ticket_submit,
    .cancel-btn {
      width: 100%; /* Making buttons cover full width */
      margin-bottom: 10px; /* Adjusted margin for smaller screens */
      margin-left: 0px;
      margin-right: 15px;
    }
  }
  