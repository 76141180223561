.footer {
  background-color: #FFFFFF;
  color: #333;
  font-family: "DM Sans";
  padding: 40px 20px; /* Adjust padding for smaller screens */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
}

.footer-logo img {
  height: 75px;
  margin-bottom: 25px;
}

.footer-titles h4, .footer-titles p {
  margin: 0;
  padding: 2px;
  font-size: 1rem;
  font-weight: 400;
}

.footer-address-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 30px;
  margin-top: 20px;
  font-size: 12px;
}

.footer-address {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-address h4, .footer-address p {
  margin: 5px 0;
  padding: 1px;
  font-size: 16px;
  font-weight: 500;
}

.footer-left {
  width: 30%;
}

.footer-menu-container {
  display: flex;
  width: 70%;
}

.footer-menu {
  width: 100%; /* Set a fixed width for each menu item */
  margin: 0 20px;
}

.footer-menu h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'DM Sans', sans-serif;

}

.footer-menu ul {
  list-style: none;
  padding: 0;
}

.footer-menu ul li {
  margin-bottom: 5px;
}

.footer-menu ul li a,
.footer-menu ul li Link  {
  color: #4D4848;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
}

.footer-menu ul li a:hover,
.footer-menu ul li Link:hover {
  color: #ff6f61;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-menu-container {
    flex-wrap: wrap;
    width: 100%;
  }
  .footer-left{
    width: 100%;
  }
  .footer-address h4, .footer-address p{
    text-align: left;
  }
  .footer-menu {
    width: calc(50% - 20px); /* Two items per row */
    margin: 10px;
  }

  .footer-address-container {
    flex-direction: column;
    align-items: center;
    gap: 1px;
  }

  .footer-address {
    margin: 10px 0;
    text-align: center;
  }
  .footer-menu ul li a,
  .footer-menu ul li Link {
  
    font-size: 1.0rem;
  }
  .footer-menu h3 {
    font-size: 1.2rem;
   
}
}
