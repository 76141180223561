.cg-wrap {
  width: 90%;
  border: none;
  margin: 0 auto;
  padding: 60px 0;
  font: 12px Arial, sans-serif;
  color: #72727d;
  min-height: 100vh;
}

.customer-section .accordion-button {
  background-color: transparent !important;
  background: none !important;
  border: none !important;
  color: #72727d;
  font-size: 16px !important;
  width: auto !important;
}

.customer-section .accordion-item {
  background-color: transparent !important;
  background: none !important;
  border: none !important;
}

.customer-section .accordion-button::after {
  display: none !important;
}

.customer-section .accordion-button:focus {
  box-shadow: none !important;
}

.customer-section .accordion-body .ans_box {
  border: 1px solid #e5e5e5;
  padding: 15px;
  background: #f5f5f5;
  position: relative;
  border-radius: 3px;
  font-size: 16px;
  line-height: 22px;
}

.customer-list {
  font-size: 16px;
  padding-bottom: 0;
  line-height: 24px;
}

.customer-section .accordion-body .notch {
  background: url(https://img2.shaadi.com/imgs/icons/faq-notch.png) no-repeat left top;
  position: absolute;
  top: -9px;
  left: 30px;
  width: 13px;
  height: 9px;
}

.customer-section .collapsed {
  color: #00bcd5 !important;
}

.customer_policy_intro {
  padding: 15px 0 0;
  color: #6b7b84;
  font-size: 18px;
}

.footer_bot_border {
  border-bottom: 1px solid #ddd;
}

.copyright_txt {
  padding: 12px 0;
}

.copyright_txt,
.copyright_lefttxt_rog {
  float: left;
  margin: 0 0 0 10px;
  text-align: left;
}

.numbers {
  color: #000 !important;
  font-size: 16px !important;
  margin-right: 5px !important;
}

.customer-section {
  background: #FFEAF4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-cover-image {
  position: relative;
  background: url('../../../../assets/images/about-cover.png') no-repeat center center;
  background-size: cover;
  height: 200px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.customer-cover-image h1 {
  color: hsl(0, 0%, 20%);
  font-size: 2.5rem;
  background: #FFEAF4;
  padding: 20px 50px;
  border-radius: 20px 20px 0px 0px;
  margin: 3px;
  position: absolute;
  bottom: -7px;
  text-align: center;
  font-family: 'Playfair Display', serif;
}

@media (max-width: 768px) {
  .cg-wrap {
      width: 100%;
      padding: 23px;
      margin: 10px;
      font-family: 'Playfair Display', serif;
      
      
  }
  .numbers {
    color: white !important;
   
  }
  .customer-section .accordion-item .accordion-header{
    background-color: #FF0666;
    margin-bottom: 15px;
    border-radius: 10px;
   
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
   }
   .customer-section .accordion-item .accordion-body{
    padding: 0px;
    font-family: 'Playfair Display', serif;
    }

  .customer-section .accordion-button {
      font-size: 14px !important;
  }
  .customer-section .accordion-item .accordion-header p{
    font-size: 16px;
    color: white;
    margin-bottom: 0px;
    font-family: 'Playfair Display', serif;
   }

  .customer-section .accordion-body .ans_box {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;
      padding-bottom: 0px;
      background: white;
  }

  .customer_policy_intro {
      font-size: 16px;
      text-align: center;
  }

  .customer-cover-image {
      height: 150px;
  }

  .customer-cover-image h1 {
      font-size: 1.5rem;
      padding: 15px 25px;
  }

  .copyright_txt,
  .copyright_lefttxt_rog {
      float: none;
      text-align: center;
      margin: 0;
  }
}


