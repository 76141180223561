/* Scope the styles under .download-app-section */
.download-app-section {
  padding: 40px 0;
  background-color: #EBEBEB;
  font-family: 'Playfair Display', serif;
}

.dt-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin-left: 50px; */
}

.dn-section-title {
  margin-left: 20px;
  color: #D23A30;
  font-size: 2.5rem;
}

.logos-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust margin between logos */
}

.logo {
  width: 174px;

}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .download-app-section{
    padding: 10px 0;
  }

  .dt-container {
    flex-direction: column;
    align-items: center;
  }

  .logos-container {
    flex-direction: row; /* Keep logos in the same row */
    justify-content: center; /* Center logos horizontally */
    margin-top: 20px; /* Add margin to separate from title */
  }

  .dn-section-title {
   font-size: 22px;
  }

  .logo {
    width: 80px; /* Further adjust logo size for smaller screens */
  }
}
