.careers {
  width: 100%;
  background-color: #fff;
}

.video-player {
  width: 100%;
}
.video-wrapper {
  position: relative;
}
.elementor-widget-wrap {
  width: 50%;
  position: absolute;
  left: 100px;
  top: 311px;
}
.elementor-heading-title1 {
  color: #000;
  font-family: "Roboto", Sans-serif;
  font-size: 56px;
  font-weight: 700;
  text-transform: none;
  line-height: 61px;
}
.elementor-heading-title2 {
  color: #656565;
  font-family: "Roboto", Sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 1.35em;
  letter-spacing: 1px;
}
.elementor-button {
  background-color: #00bcd5;
  color: #fff;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 17px;
  font-weight: 500;
  margin-top: 30px;
}

.heading-wrapper {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.career-heading {
  font-size: 48px;
  font-weight: 500;
  color: #000;
}
.container-box {
  flex-direction: column;
}
#uncontrolled-tab-example {
  width: 100%;
}
.careers .nav-link {
  border-bottom: 0 !important;
}
.careers .active {
    border-bottom: 0 !important;
  }
.know-text {
  font-size: 24px;
  color: #72727d;
  margin-top: 40px;
}
.tab-content {
  border: 1px solid #dee2e6;
  padding: 0px 100px 0px 40px;
  text-align: justify;
}

.box-wrapper{
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     gap: 20px;
     margin-top: 80px;
     margin-bottom: 40px;
}
.know-box {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}
.main-text {
    font-family: "Roboto", Sans-serif;
    color: #51505d;
    font-size: 36px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 42px;
    margin-bottom: 0;
}
.sub-text {
    color: #51505d;
    font-family: "Roboto", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    line-height: 26px;
    letter-spacing: 1px;
    margin-bottom: 0;
}
.careers .sub-heading {
    color: #656565;
    font-size: 24px;
    text-align: center;
    font-weight: 400;
}
.sub-heading-wrapper {
    width: 100%;
   margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .careers .accordion {
    border: 0 !important;
    width: 100%;
  }
  .careers .accordion .accordion-item {
    border: 0 !important;
  }
  .careers  .accordion .accordion-button {
    font-size: 28px;
    font-weight: 500;
    text-transform: none;
    line-height: 29px;
    letter-spacing: 1px;
    color: #656565;
  }
  .careers .accordion .accordion-button:focus {
    border: 0 !important;
    box-shadow: none !important;
  }
  .progression-body {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .progression-text{
    font-size: 20px;
    color: #656565;
  }
  .progression-left-container {
    width: 50%;
    padding: 40px;
  }
  .progression-right-container {
    width: 50%;
    padding: 40px;
    display: flex;
    justify-content: center;
  }
  .letter-style{
    font-style: italic;
  }
  .letter-highlight {
    color: #656565;
    font-style: italic;
    font-weight: 700;
  }
  .progression-right-container img {
    border-radius: 6px;
    width: 300px;
  }
  .work-img {
    border-radius: 6px;
    width: 300px;
  }
  .culture-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .culture-left-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 20px;
    width: 50%;

  }
  .culture-right-box {
    padding:0 20px;
    width: 50%;

  }
  .video-player-culture{
    width: 80%;
  }
  .culture-heading{
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .culture-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5em;
  }
  .culture-btn {
    margin-top: 40px;
    padding: 12px 24px !important;
    background-color: #00bcd5 !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border: 1px solid #00bcd5 !important;
  }
  .culture-btn:hover {
    margin-top: 40px;
    padding: 12px 24px !important;
    background-color: #fff !important;
    color: #00bcd5 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    border: 1px solid #00bcd5 !important;
  }
  .benefit-bg {
    background-color: #f8f8f8;
    padding: 80px 0;
  }

  .know-bg {
    background-color: #fff;
    padding: 80px 0;
  }
  @media (max-width:1199px) {
    .elementor-widget-wrap{
      top: 200px;
    }
    .elementor-heading-title1{
      font-size: 50px;
    }
    .elementor-heading-title2{
      font-size: 24px;
    }
    .know-bg {
      padding: 40px 0;
    }
    .heading-wrapper{
      margin-bottom: 30px;
    }
  }
  @media (max-width:991px) {
    .elementor-widget-wrap{
      top: 80px;
      left: 50px;
      width: 65%;
    }
    .elementor-heading-title1{
      font-size: 40px;
      line-height: 48px;
    }
    .career-heading{
      font-size: 40px;
    }
    .know-text {
      font-size: 18px;
      margin-top: 30px;
    }
    .tab-content {
      padding: 0px 30px 0px 30px;
    }
    .box-wrapper{
      margin-top: 40px;
    }
    .know-box{
      padding: 15px;
    }
    .main-text {
      font-size: 24px;
      line-height: 38px;
    }
    .sub-text{
      font-size: 14px;
    }
    /* #uncontrolled-tab-example{
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
    } */
    /* #uncontrolled-tab-example li{
      flex: 0 0 auto;
    } */
    #uncontrolled-tab-example{
      justify-content: center;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
      border-bottom: var(--bs-nav-tabs-border-width) solid #dee2e6!important;
    }
    .benefit-bg{
      padding: 40px 0;
    }
    .careers .sub-heading{
      font-size: 20px;
    }
    .careers .accordion .accordion-button{
      font-size: 24px;
      padding: 15px;
    }
    .progression-body{
      margin-top: 0;
    }
    .culture-container{
      margin-top: 30px;
      margin-bottom: 0;
    }
    .culture-heading {
      font-size: 42px;
      margin-bottom: 10px;
    }
    .culture-text{
      font-size: 20px;
    }
    .culture-btn{
      margin-top: 20px;
    }
    .progression-right-container,.progression-left-container{
      padding: 20px;
    }
  }
  @media (max-width:767px) {
    .progression-body{
      flex-direction: column-reverse;
    }
    .progression-right-container, .progression-left-container,.culture-left-box,.culture-right-box {
      width: 100%;
    }
    .culture-container{
      flex-wrap: wrap;
      margin-top: 0;
    }
    .culture-heading,.career-heading {
      font-size: 32px;
    }
    .heading-wrapper{
      margin-bottom: 20px;
    }
  }
  @media (max-width:575px) {
    .elementor-heading-title1 {
      font-size: 30px;
      line-height: 38px;
    }
    .elementor-heading-title2{
      font-size: 20px;
    }
    .elementor-button {
      padding: 10px 15px;
      font-size: 15px;
    }
    .tab-content {
      padding: 0px 15px;
    }
    .know-text {
      font-size: 16px;
      margin-top: 15px;
    }
    .box-wrapper{
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 15px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .know-box{
      max-width: 47%;
    }
  }
  @media (max-width:480px) {
    .elementor-widget-wrap {
      top: 25px;
      left: 20px;
      width: 80%;
    }
    .elementor-heading-title1 {
      font-size: 22px;
      line-height: 26px;
    }
    .elementor-heading-title2 {
      font-size: 18px;
    }
    .know-box{
      max-width: 100%;
    }
  }