/* MatrimonyService.css */

.matrimony-service {
  background-color: #fff;
  padding-top: 50px;
  font-family: 'Playfair Display', serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center; /* Center align items vertically */
}

.left-column {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.success-stories {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.success-story {
  width: calc(50% - 10px); /* Set width of each success story item with space between */
}

.divider-verticals {
  width: 5px;
  background-color:  #83e1ed;
  height: 380px; /* Adjust height as needed */
  margin: 0 20px; /* Add margin for better spacing */
}

.right-column {
  margin-left: 10px;
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: start;
}

.section-title {
  font-size: 3rem;
  color: #4a4a4a;
  padding: 19px;
}

.section-title .hl {
  color: #FF4D77; /* Set color for the word "Millions" */
}

.ml-view-all-button {
  width: 40%;
  background-color:  #83e1ed;
  color: #fff;
  border: none;
  padding: 13px 40px;
  font-size: 2.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  margin: 50px auto;
  margin-bottom: 0 !important;
  display: block;
  font-family: 'Playfair Display', serif;
}

.ml-view-all-button:hover {
  background-color: #5ac5c5;
}



/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .matrimony-service {
    
    padding-top: 20px;
    font-family: 'Playfair Display', serif;
  }
  .container {
    flex-direction: column;
    align-items: center;
  }

  .success-stories {
    flex-direction: column;
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
}

  .divider-verticals {
   display: none;
  }

  .left-column {
    width: 100%;
    order: 2;
  }

  .right-column {
    width: 100%;
    order: 1;
  }

  .success-story {
    width: 90%; /* Adjust width for smaller screens */
  }

  .section-title {
    font-size: 18px;
    text-align: center;
    padding: 15px;
  }

  .ml-view-all-button {
    width: 60%;
    font-size: 18px;
    margin-top: 10px;
  }
}
