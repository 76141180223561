.matrimonial-form-container {
  padding: 40px;
}
.matrimonial-header {
  position: relative;
  font-size: 20px;
  font-weight: 900;
  text-shadow: 1px 1px 10px #2e2e2e;
  text-align: center;
  color: #fff;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #74083ec9;
  background: linear-gradient(
    90deg,
    rgba(166, 3, 139, 1) 0%,
    rgba(255, 0, 236, 1) 50%,
    rgba(255, 0, 116, 1) 100%
  );
}

.matrimonial-form {
  background-color: #ffffff;
  margin-top: 30px;
  padding: 30px;
}

.details-title {
  font-size: 16px;
  font-weight: 500;
  margin: 6px 0;
  color: #333;
}

.matrimonial-label {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
  padding-bottom: 5px;
}

.matrimonial-form-input,
.matrimonial-select-input {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 10px;
  height: 42px;
  margin: 8px 0;
}

input[type="email"],
input[type="date"],
input[type="tel"] {
  width: 80%;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item-left {
  display: flex;
  padding: 5px;
  flex: 30%;
  flex-direction: column;
}

.flex-item-center {
  display: flex;
  flex-direction: column;
  padding: 5px;
  flex: 30%;
}

.flex-item-right {
  display: flex;
  flex-direction: column;
  padding: 5px;
  flex: 30%;
}
.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 298px;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin: 25px 0;
  background-color: #4070f4;
  transition: all 0.3s linear;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  /* margin-top: 15px; */
}
.logout-button {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 100px;
  width: 50%;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  margin: 25px 15px;
  background-color: #4070f4;
  transition: all 0.3s linear;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  /* margin-top: 15px; */
}
.error-message {
  font-size: 13px;
  color: red;
}
.matrimonial-label span {
  color: red;
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
}
