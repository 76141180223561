.modal-backdrop {
    z-index: 9999999999 !important ;
}
 .modal-dialog {
    z-index: 9999999999999 !important;
}
.modal {
    z-index: 999999999999999 !important;
}
.modal .form-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.modal-body {
    padding: 25px !important;
}
.step-btn {
    width: 100%;
    margin: 20px 0;
}