.register-page{
    font-family: 'DM Sans', sans-serif;
    background-color: #FF7B8B;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative; 
}

.register-page .form-main-div {
    background: #fff;
    padding: 70px 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 600px;
    margin: 0 auto;
    position: relative;
    margin-top: 50px;
}
.register-page .form-main-div h3{
    font-family: 'DM Sans', sans-serif !important;
    line-height: 40px;
    margin-bottom: 25px;
    font-size: 30px;
}
.register-page .date-fields {
    display: flex;
    align-items: center;
}

.register-page .top-right-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .register-page .country-code-field{
    width: 20% !important;
  }
  .register-page .top-right-buttons a {
    margin-left: 10px;
    /* width: 15%; */
    text-decoration: none;
    text-align: center;
    
  }
  
  .register-page .language-button,
  .register-page .register-button {
    font-family: 'Playfair Display', serif;
    padding: 10px 20px;
    background-color: #fff;
    color:#000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
.register-page .error{
    color: red;
    font-size: 14px;
    
}
.register-page label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    margin-top: 20px;
}


.register-page input, .register-page select, .gender-options p, .relationship-options p {
    width: 100%;
    padding: 10px;
    margin: 5px 3px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.register-page .gender-options, .register-page .relationship-options {
    display: flex;
    flex-wrap: wrap;
}

.register-page .gender-options p, .register-page .relationship-options p {
    cursor: pointer;
    background: #f9f9f9;
    border: 1px solid #ddd;
    text-align: center;
    width: 30%;
    margin-right: 10px;

}

.register-page .gender-options p:hover, .register-page .relationship-options p:hover {
    background: #eee;
}

.register-page button {
    background:  #83e1ed;
    color: #fff;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
    width: 100%;
    margin: 20px 0px;
    padding: 10px;
    font-size: 1.5rem;
}
@media (max-width: 480px) {
    .register-page .form-main-div {
        width: 90%;
        padding: 20px 10px;
        margin-top: 50px;
    }
    .register-page .relationship-options p {
        width: 40%;
    }

    .register-page .form-main-div h3 {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 15px;
    }

    .register-page .top-right-buttons {
        top: 5px;
        right: 5px;
        align-items: flex-start;
    }

    .register-page .language-button,
    .register-page .register-button {
        font-size: 0.9em;
        padding: 8px 16px;
    }

    .register-page button {
        font-size: 0.8rem;
        padding: 10px;
    }
}