@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

/* Reset default styles */
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Playfair Display', serif;
  width: 100%;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}