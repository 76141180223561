.navigation {
  /* background-color: #f8f8f8; Navigation background color */
  /* border-bottom: 1px solid #ddd; */
}

.toggle_ul {
  width: 100vw;
  color: white;
  position: absolute;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #ff0666;
}

.navigation ul {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin-right: 40px;
}

.navigation li:last-child {
  margin-right: 0px !important;
}

.navigation a {
  font-family: "Playfair Display", serif;
  text-decoration: none;
  color: #fff; /* Navigation link color */
  font-size: 1.188rem;
  padding: 10px 20px;
  display: block;
  position: relative;
}

.navigation.scrolled a {
  color: #83e1ed; /* Change this to your desired link color */
}

.navigation a:hover:before {
  background-color: #fff;
  content: "";
  position: absolute;
  left: 20px;
  bottom: 4px;
  width: 36px;
  height: 3px;
  transition: all 0.3s ease;
}

.navigation.scrolled a:hover:before {
  background-color: #83e1ed;
}

.button-link {
  font-family: "Playfair Display", serif;
  padding: 10px 20px;
  background-color: #fff;
  color: #000000 !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation.scrolled .button-link {
  background: #83e1ed;
  color: #fff !important;
  transition: all 0.3s ease;
}

.button-link:hover {
  color: #ff6f61 !important; /* Change text color on hover */
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  z-index: 10;
}

.hamburger div {
  width: 30px;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.hamburger div:nth-child(2) {
  width: 25px;
}

.menu {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #333;
  width: 100%;
  text-align: center;
}

.menu.open {
  display: flex;
}

.menu a {
  padding: 10px 20px;
  color: #fff;
}

.menu a:hover {
  color: #ff6f61;
}
.small_li {
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  padding-left: 10px;
  width: 100%;
  height: 100%;
  margin-top: 5px;
}
.btn_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.menu_bar_logo {
  display: none;
}
.menu_bar {
  display: none;
}
.toggle_ul {
  display: none !important;
}
/* Mobile Styles */
@media only screen and (max-width: 768px) {
  .navigation ul {
    flex-direction: row; /* Stack items vertically on small screens */
    align-items: center; /* Center items horizontally */
  }

  .navigation.scrolled a {
    color: white; /* Change this to your desired link color */
  }

  .menu_bar {
    display: flex;
  }
  .menu_bar_logo {
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 30px;
    img {
      width: 20px;
    }
  }
  .line{
    display: flex;
    justify-content: space-between;
    width: 100%;
    stroke: white!important;
    color: white;
  }
  .navigation li {
    margin: 5px 2px; /* Add margin between items */
  }

  /* .toggle_ul {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
  .toggle_ul li {
    display: block !important;
    width: 100%;
    color: white !important ;
  }
  .toggle_ul li a {
    color: white !important ;
  } */
  .toggle_ul {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }

  .toggle_ul li {
    display: block !important;
    width: 100%;
    color: white !important;
  }

  .toggle_ul li a {
    color: white !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggle_ul li a::after {
    content: '>';
    margin-right: 33px;
    padding-left: 10px;
    font-size: 1.488rem;
  }
  .navigation li:not(.essential-link) {
    display: none;
    /* Hide non-essential links */
  }
}
