@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@300;400;500;600;700&display=swap');
body {
    font-family: 'Playfair Display', serif;
    color: #333;
    margin: 0;
    padding: 0;
}

.about-section {
    background: #FFEAF4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-cover-image {
    position: relative;
    background: url('../../../../assets/images/about-cover.png') no-repeat center center;
    background-size: cover;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.about-cover-image h1 {
    color: hsl(0, 0%, 20%);
    font-size: 4rem;
    background: #FFEAF4;
    padding: 35px 150px;
    border-radius:37px 37px 0px 0px;
    margin: 3px;
    position: absolute;
    bottom: -7px;
    text-align: center;
    font-family: 'Playfair Display', serif;
}

.about-content {
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    background: #FFEAF4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Playfair Display', serif;
    color: #333;
}

.about-paragraph-container {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.about-paragraph {
    font-size: 2rem;
    margin: 0 auto;
    padding: 20px;
    max-width: 800px; /* This ensures the paragraph doesn't stretch too wide on large screens */
    line-height:42.66px;
    color: #000000;
    font-weight: 500;

}
@media only screen and (max-width: 768px) {
    .about-cover-image {
        height: 150px;
    }

    .about-cover-image h1 {
        font-size: 1.5rem;
        padding: 20px 50px;
        border-radius: 1px; /* Adjusted border-radius for mobile */
        bottom: -17px; /* Adjusted bottom position for mobile */
    }

    .about-content {
        padding: 10px; /* Adjusted padding for mobile */
    }

    .about-paragraph {
        font-size: 0.9rem;
        line-height: 24px;
        font-weight: 700;
    }
}