.rl-detail-container {
    /* background: linear-gradient(180deg, #FFFFFF 0%, #FCE5F0 56.68%); */
    background: #FCE5F0;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
  }
  
  .rl-detail-content {
    color: #000000;
    font-family: 'Playfair Display', serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align content to the left */
    max-width: 1200px; /* Adjust max-width as needed */
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    margin-left: 100px; /* Adjust margin-left as needed */
    box-sizing: border-box;
  }
  
  .rl-detail-text {
    flex: 1;
    margin-top: 20px;
  }
  
  .rl-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #AD1969;
    font-family: 'Playfair Display', serif;
  }
  
  .rl-title .ampersand {
    font-family: 'DM Sans', sans-serif;
    color: #AD1969;
  }
  
  .rl-detail-text p, .rl-detail-text h2, .rl-detail-text ul {
    font-size: 1rem;
    line-height: 1.6;
    color: #000000;
    font-weight: 600;
    text-align: justify;
  }
  
  .rl-detail-text ul {
    list-style: disc;
    margin-left: 20px;
  }
  
  .apply-now-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensure the container takes the full width of its parent */
    margin-top: 20px; /* Add margin to separate from the content above */
  }
  
  .apply-now-btn {
    background-color: #AD1969;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .apply-now-btn:hover {
    background-color: #8B1453;
  }
  @media screen and (max-width: 768px) {
    .rl-detail-content {
      margin-left: 0; /* Remove margin for smaller screens */
    }
  }