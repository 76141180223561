@import url('https://fonts.googleapis.com/css2?family=Marko+One&display=swap');
.about-detail-container {
  background: #FCE5F0;
  padding: 120px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-detail-columns {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.about-detail-left {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.about-detail-left h2 {
  font-size: 3rem;
  color: #000;
  margin-bottom: 20px;
  margin-top: 0;
  width: 575px;
}

.about-detail-image-container {
  position: relative;
  width: 300px; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 225px auto; /* Center the image container */
}

.circle {
  position: absolute;
  width: 108px;
  height: auto;
}

.square {
  position: absolute;
  width: 30%;
  height: auto;
}

.circle1 {
  top: -185px;
  left: -119px;
  z-index: 0;
}

.main-image {
  width: 440px;
  height: auto;
  border-radius: 5px;
  z-index: 1;
}

.overlay-image {
  position: absolute;
  width: 469px;
  height: auto;
  top: 91%;
  left: 114%;
  transform: translate(-50%, -50%);
  z-index: 2
}

.square2 {
  bottom: -170px;
  right: -297px;
  z-index: 3;
}

.about-detail-right {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
}

.about-detail-right .about-detail-text {
  text-align: start;
  position: relative;
  top: -122px;
  right: 101px;
  width: 100%;
}

.about-detail-right .about-detail-text .head {
  color: #522B79;
  font-size: 5rem;
  font-weight: 100;
}
.about-detail-right h1 {
  font-weight: 100;
  font-size: 3rem;
  color: #E33183;
  font-family: 'Marko One', sans-serif;

}

.about-detail-paragraph {
  margin-top: 55px;
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.about-detail-paragraph p {
  font-family: 'Playfair Display', serif;
  width: 100%;
  font-size: 1.5rem;
  line-height:31.99px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  text-align: start;
  padding: 10px;
}
@media only screen and (max-width: 768px) {
  .about-detail-container {
    padding: 80px 20px;
  }

  .about-detail-paragraph {
    margin-top: 10px;
    display: block;
  } 

  .about-detail-columns {
    flex-direction: column;
  }

  .about-detail-left {
    margin-bottom: 40px;
    text-align: center;
  }

  .about-detail-left h2 {
    font-size: 2rem;
    width: auto;
    margin: 0 auto 20px;
  }

  .about-detail-image-container {
    width: 140px;
    height: 158px;
    margin: 70px 75px 21px;
  }

  .circle1 {
    width: 30%;
    top: -75px;
    left: -50px;
  }

  .main-image {
    width: 200px;
    height: auto;
  }

  .overlay-image {
    width: 220px;
    top: 80%;
    left: 90%;
    transform: translate(-50%, -50%);
  }

  .square {
    width: 20%;
  }

  .square2 {
    width: 37%;
    bottom: -62px;
    right: -101px;
  }

  .about-detail-right {
    align-items: center;
    text-align: center;
  }

  .about-detail-right .about-detail-text {
    text-align: center;
    top: 0;
    right: 0;
  }

  .about-detail-right .about-detail-text .head {
    font-size: 1.5rem;
  }

  .about-detail-right h1 {
    font-size: 1.5rem;
    margin-top: 30px;
  }

  .about-detail-paragraph p {
    font-size: 1.2rem;
    line-height: 1.6;
    text-align: center;
  }
}