body {
    font-family: 'Playfair Display', serif;
    color: #333;
    margin: 0;
    padding: 0;
}

.services-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-cover-image {
    position: relative;
    background: url('../../../../assets/images/service-cover.png') no-repeat center center;
    background-size: cover;
    height: 550px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.welcome-text{
    z-index: 99;
    font-size: 3rem;
    font-weight: 500;
    line-height: 63.98px;
    margin-bottom: 0;
}
.welcome-heading {
    color: #333;
    font-size: 2.5em;
    background: #FFEAF4;
    padding: 140px 140px;
    border-radius: 50%; /* Use border-radius to make it round */
    margin: 0;
    position: absolute;
    bottom: -130px;
    left: 50%; /* Center the circle */
    transform: translateX(-50%); /* Center the circle */
}

.centered-heading {
    font-family: 'Playfair Display', serif;
    position: absolute;
    font-weight: 400;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Adjust color as necessary */
    font-size: 6rem; /* Adjust size as necessary */
    /* background: rgba(0, 0, 0, 0.5); Optional: Add a semi-transparent background */
    padding: 10px 20px; /* Optional: Add padding */
    border-radius: 10px; /* Optional: Add rounded corners */
}

.services-content {
    width: 100%;
    background: #FFEAF4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.services-heading, .services-subheading {
    font-family: 'Playfair Display', serif;
    text-align: center;
    margin-top: 125px;
    margin-bottom: 55px;
    font-size: 3rem;
    z-index: 99;
}

.service-find-stories {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    width: 100%;
    max-width: 800px; /* Limit the maximum width */
    margin: 0 auto;
}

.service-find-story {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 2px;
    margin: 10px 0; /* Separate items */
    width: 100%; /* Full width */
   
}

.service-find-story img {
    margin: 0 10px; /* Margin for the icon */
}

  .icon-wrapper {
    position: relative;
  }
  .back-design{
    width: 66px;
    height: 38px;
    overflow: hidden;
    position: absolute;
    left: 31px;
    top: 59px;
    z-index: 999;
  }
.service-bullet-icon {
    width: 100px;
    height: 107px;
    margin-top: 5px; /* Align icon with text */
}

.service-story-content {
    color: #50DADA;
    display: flex;
    flex-direction: column;
    font-family: 'Playfair Display', serif;
}

.service-story-content-cn {
    display: flex;
    flex-direction: column;
    font-family: 'Playfair Display', serif;
    margin-left: 65px;
}

.service-story-content-cn h4 {
    align-self: flex-end;
    margin-right: 5px;
}

.service-find-story h4 {
    margin: 0 1px 5px;
    font-size: 2rem;
    color: #50DADA;
}

.service-find-story p {
    margin: 0;
    font-size: 1.5rem;
    color: #000000;
}

.services-paragraph-container {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}

.services-paragraph {
    font-family: 'Playfair Display', serif;
    font-size: 2.25rem;
    margin: 0 auto;
    max-width: 1300px;
    line-height: 1.6;
    font-weight: 600;
    color: #000000;
}
@media only screen and (max-width: 768px) {
    .services-cover-image {
        height: 300px;
    }

    .welcome-text {
        font-size: 1.5rem;
    }

    .welcome-heading {
        padding: 66px;
        bottom: -50px;
    }

    .centered-heading {
        font-size: 3rem;
        top: 25%;
    }

    .services-heading, .services-subheading {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .service-find-story h4 {
        font-size: 1.0rem;
    }

    .service-find-story p {
        font-size: 0.8rem;
    }

    .services-paragraph {
        font-size: 0.9rem;
    }

    /* .service-find-story {
        flex-direction: column;
        align-items: center;
        padding: 20px;
        text-align: center;
        width: 100%;
    } */


    .service-find-stories {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        width: 100%;
        max-width: 800px; /* Limit the maximum width */
        margin: 0 auto;
    }
    .service-find-story {
        display: flex;
        align-items: flex-start;
        padding: 0px;
        border-radius: 2px;
        margin: 0px; /* Separate items */
        width: 100%; /* Full width */
       
    }

    .service-find-story img {
        margin: 10px 10px;
    }

    .service-bullet-icon {
        width: 50px;
        height: 57px;
        /* margin-top: 5px; Align icon with text */
    }
    .service-story-content {
        margin-top: 10px;
    }

    .icon-wrapper {
        margin-bottom: 20px;
    }

    .back-design {
        width: 33px;
        height: 19px;
        left: 15px;
        top: 30px;
        display: none;
    }
    .service-story-content-cn {
      margin-top: 10px;
    }
}