.start-by-millions-wrapper{
    display: flex;
    padding: 40px 0;
    font-family: 'Playfair Display', serif;
    background: #FF4D77;
    text-align: center;
  }
  .start-by-millions-section{
    padding-top: 70px;
    padding-bottom: 45px;
  background-color: white;
  }
  .start-by-millions-content {
    margin-left: 25px;
    /* display: flex;
    justify-content: space-around; Adjusted to evenly distribute space between elements */
    align-items: center;
    margin: 0 auto;
    max-width: 1200px; /* Optional: to limit the width of the container */
  }
  
  .start-by-millions-button {
    margin-left: 45px;
    background-color: #22B8B8;
    font-family: 'Playfair Display', serif;
    color: #FFFFFF;
    border: none;
    padding: 13px 45px;
    font-size: 2.2rem;
    max-width: 300px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    margin-top: 25px;
    transition: background-color 0.3s ease;
  }
  
  .start-by-millions-button:hover {
    background-color: #1a8a8a;
  }
  
  .start-by-millions-text {
    font-size: 2.25rem; 
    color: #FFFFFF;
    flex-grow: 1; /* Use flex-grow to distribute remaining space */
  }
  @media (max-width: 768px) {
    .start-by-millions-content {
      padding: 0 20px; /* Add padding to the content for smaller screens */
    }
    .start-by-millions-text {
      font-size: 20px;
    }
    .start-by-millions-button {
      margin-left: 1px;
      background-color: #22B8B8;
      font-family: 'Playfair Display', serif;
      color: #FFFFFF;
      border: none;
      padding: 13px 45px;
      font-size: 1.2rem;
      max-width: 300px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      cursor: pointer;
      /* margin-top: 25px; */
      transition: background-color 0.3s ease;
    }

  }