/* Scope the styles under .trusted-by-millions-section */
.trusted-by-millions-section {
  padding: 40px 20px; /* Adjust padding for mobile */
  font-family: 'Playfair Display', serif;
  background: #EBEBEB;
  text-align: center;
  font-size: 1.875rem;
}

.trusted-by-millions-content {
  display: flex;/* Stack button and text vertically on mobile */
  align-items: center; /* Center button and text horizontally */
  margin: 0 auto;
  width: 100%;
}

.trusted-by-millions-button {
  background-color: #22B8B8;
  font-family: 'Playfair Display', serif;
  color: #FFFFFF;
  border: none;
  padding: 15px 40px;
  font-size: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  width: 314px;
  transition: background-color 0.3s ease;
  margin-right: 15px;
}

.trusted-by-millions-button:hover {
  background-color: #1a8a8a;
}

.section-p {
  color: #000000;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.875rem; /* Adjust font size for mobile */
  margin: 0; /* Remove default margin */
  text-align: center; /* Center text */
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .trusted-by-millions-content{
    flex-wrap: wrap;
    justify-content: center;
  }
  .trusted-by-millions-button{
    margin-bottom: 10px;
    font-size: 0.9rem;
    padding: 10px 34px;
    width: 220px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .trusted-by-millions-section{
    padding: 20px;
  }
  .section-p{
    font-size: 1.1rem;
  }
}
