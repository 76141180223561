.story-detail-container {
  background: linear-gradient(180deg, #FFFFFF 0%, #FCE5F0 56.68%);
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 80%;
  box-sizing: border-box;
}

.story-detail-content {
  color: #000000;
  font-family: 'Playfair Display', serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1300px;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}

.story-detail-image-container {
  position: relative;
  align-self: flex-start;
  margin-bottom: 20px;
  width: 320px; /* Adjust width as needed */
  height: 260px; /* Adjust height as needed */
}

.story-detail-image {
  position: relative;
  width: 580px;
  height: 439px;
  object-fit: fill;
  border-radius: 5px;
  z-index: 1;
  margin: auto;
}

.rectangle {

  position: absolute;
  width: 123px; /* Adjust width as needed */
  height: 93px; /* Adjust height as needed */
  /* border: 2px solid #AD1969; Adjust border color as needed */
  z-index: 0;
}

.rectangle-top-left {
  top: -14px;
  left: -14px;
}

.rectangle-bottom-right {
  bottom: -194px;
  right: -267px;
}

.story-detail-text {
  flex: 1;
  margin-top: 230px;
}

.story-detail-text .story-title {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #AD1969;
  font-family: 'Playfair Display', serif;
}

.story-detail-text .story-title .ampersand {
  font-family: 'DM Sans', sans-serif;
  color: #AD1969;
}

.story-detail-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #000000;
  font-weight: 600;
  text-align: justify;

}
/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .story-detail-content {
    padding: 20px;
    margin-top: 20px;
  }

  .story-detail-text {
    margin-top: 15px;
  }

  .story-detail-text .story-title {
    margin-bottom: 20px;
    font-size: 16px;
    color: #AD1969;
    font-family: 'Playfair Display', serif;
  }

  .story-detail-image-container {
    position: relative; /* Change to relative positioning for children */
    width: 100%; /* Occupy full width */
    max-width: 100%; /* Limit width on smaller screens */
  }

  .story-detail-image {
    width: 100%; /* Occupy full width */
    height: auto; /* Maintain aspect ratio */
  }

  .rectangle {
    position: absolute;
    width: 30%; /* Adjust width as needed */
    height: 30%; /* Maintain aspect ratio */
    z-index: 0;
    background-size: cover;
  }

  .rectangle-top-left {
    top: -4%;
    left: -4%;
  }

  .rectangle-bottom-right {
    bottom: -1%;
    right: -3%;
  }
  /* Adjust the position and size of the rectangles */
  .story-detail-text p {
    font-size: 15px;
    line-height: 1.1;
    color: #000000;
    font-weight: 600;
    text-align: justify;
  
  }
}
