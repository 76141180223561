.ml-login-section {
    font-family: 'DM Sans', sans-serif;
    background-color: #FF7B8B;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative; /* Added position relative to control absolute elements */
  }
  
  .ml-top-right-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .ml-top-right-buttons a {
    margin-left: 10px;
    text-decoration: none;
    text-align: center;
  }
  
  .ml-language-button,
  .ml-register-button {
    font-family: 'Playfair Display', serif;
    padding: 10px 20px;
    background-color: #fff;
    color:#000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .ml-login-container {
    margin-top: 2px;
  }
  
  .ml-login-form {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    padding-left: 50px;
    padding-top: 38px;
    padding-right: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    margin-top: 90px;

  }
  
  .ml-login-form h4 {
    text-align: start;
    margin-bottom: 20px;
    font-family: 'DM Sans', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    line-height: 41.66px;
  }
  
  .ml-login-form-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  input[type="text"],
  input[type="password"],
  .ml-login-button,
  .ml-continue-button {
    width: 100%; /* Adjusted width to be full */
    padding: 10px;
    margin: 7px 0; /* Adjusted margin to maintain uniform spacing */
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
  }
  
  .ml-additional-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;
    width: 100%;
  }
  
  .ml-additional-options label {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 15px;
  }
  
  .ml-additional-options a,
  .ml-additional-options .forget-b {
    text-decoration: none;
    color:#939393;
    font-size:15px;
  }
  
  .ml-login-button {
    background-color: #83e1ed;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .ml-continue-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 60px;
  }
  
  .ml-divider {
    text-align: center;
    margin: 20px 0;
  }
  
  .ml-divider span {
    display: inline-block;
    padding: 0 10px;
    color: #ccc;
  }

  .ml-forget-popup-div .ml-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ml-forget-popup-div .ml-popup-content {
    background: white;
    padding: 35px 20px;
    border-radius: 10px;
    text-align: center;
    width: 500px;
  }
  
  .ml-forget-popup-div .ml-form-group-div {
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
  }
  .ml-forget-popup-div .ml-email-input-field {
    width: 100%;
    padding: 10px;
    margin: 20px 0px;
    border: 1px solid #555555;
    border-radius: 5px;
  }
  .ml-forget-popup-div .ml-error-message {
    color: red;
    font-size: 0.8em;
  }
  
  .ml-forget-popup-div .ml-success-message {
    color: green;
    font-size: 0.8em;
    margin-bottom: 10px;
  }
  
  .ml-forget-popup-div .ml-submit-button {
    background: #83e1ed;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    width: 50%;
    border-radius: 5px;
  }
  .ml-forget-popup-div .ml-close-icon{
    margin-left: auto;
    max-width: 28px;
    float: inline-end;
  }
  
  /* .close-button {
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
   */
   @media (max-width: 480px) {
    
    .ml-login-form {
      padding: 15px;
      padding-top: 30px;
      margin-top: 50px;
    }
  
    .ml-login-form h4 {
      font-size: 1.0rem;
      line-height: 20px;
      padding-right: 90px;
    }

    .ml-top-right-buttons {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  
    .ml-top-right-buttons a {
      font-size: 1.0em;
      padding: 10px 10px;
    }
  
    .ml-additional-options {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .ml-additional-options label,
    .ml-additional-options a,
    .ml-additional-options .forget-b {
      font-size: 0.7em;
    }
  
    .ml-forget-popup-div .ml-popup-content {
      width: 90%;
      padding: 15px;
    }

    .ml-language-button,
    .ml-register-button {
      font-family: 'Playfair Display', serif;
      padding: 10px 20px;
      background-color: #fff;
      color:#000000;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.5rem;
    }
    .ml-divider{
      display: none;
    }
    .ml-login-button{
      font-size: 1.0rem;
    }
    .ml-continue-button{
      font-size: 1.0rem;
    }
  }