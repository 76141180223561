@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.header {
  position: relative;
  overflow: hidden;
  transition: background-color 0.6s ease;
  /* Add transition for smooth effect */
}

.sticky-header {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 0;
  width: 100%;
  background-color: none;
  /* Background color to avoid content overlap */
  z-index: 1000;
  /* High z-index to ensure it stays above other content */
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); Optional: add a shadow for better visual distinction */
}

.header.scrolled .nav-wrapper a {
  color: #83e1ed;
  /* Change this to your desired link color */
}

.hero-section {
  position: relative;
  width: 100%;
  height: 970px;
  background-image: url("../../../assets/images/cover.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.search-form-wrapper {
  width: 100%;
}

.hero-content {
  color: #fff;
  margin-top: 160px;
  width: 100%;
  padding-left: 16px;
}

.hero-content h1 {
  font-size: 64px;
  line-height: 80px;
  font-family: "Playfair Display", serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.hero-content h1 .dm-sans-font {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  display: inline;
  color: #FFF;
}

.hero-content h1 span.highlight {
  color: #f51e92;
}

.search-intro {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px 100px;
  border-radius: 2px;
  display: inline-block;
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
  color: #83e1ed;
}

.search-form {
  margin-top: 70px;
  padding: 70px;
  /* Adjust padding */
  font-family: "Playfair Display", serif;
  border-radius: 20px;
  background-color: white;
  /* Background color for the entire form */
}

.search-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* Proper padding */
  border-radius: 10px;
  /* Rounded corners */
  background-color: white;
  /* Background color for the row */
}

.search-row .select-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  /* Space between select groups */
  color: #000000;
}

.search-row .s-header {
  margin: 0 0 10px;
  color: #000000;
  font-size: 1.25rem;
  font-weight: 500;
}

.search-row select,
.search-row input[type="text"] {
  padding: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-family: "Playfair Display", serif;
  color: #939393;
  border: 1px solid #ccc;
  margin-right: 10px;
  /* Space between elements */
}

.search-row select {
  width: 220px;
  padding: 10px 15px;
  font-size: 0.875rem;
  width: 257px;
}

.cta-button {
  padding: 10px 70px;
  /* Adjust padding */
  font-size: 1.25rem;
  width: 257px;
  font-family: "Playfair Display", serif;
  background-color: #83e1ed;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 25px;
  /* Add margin for spacing */
  font-weight: 700;
}

.scrolled {
  background-color: white !important;
  transition: 0.6s ease;
}

.header-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 15px 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.116);
  /* background: red; */
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0px 0px 10px 10px;
}

.header.scrolled .header-content {
  background: #fff;
  transition: all 0.3s ease;
}

.nav-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  height: 60px;
}

.auth-buttons {
  display: flex;
  align-items: center;
}

.auth-buttons .login-button,
.auth-buttons .help-button {
  margin-left: 15px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
}

.auth-buttons .login-button:hover,
.auth-buttons .help-button:hover {
  background-color: white;
  color: #d23a30;
  transition: background-color 0.3s, color 0.3s;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {

  .hero-content {
    margin-top: 100px;
  }

  .hero-content h1 {
    line-height: 30px;
    font-size: 20px;
  }

  .hero-section {
    position: relative;
    width: 100%;
    height: 550px;
    background-image: url("../../../assets/images/cover.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .search-intro {
    font-size: 16px;
    /* Adjust font size for smaller screens */
    padding: 10px;
  }

  .search-form {
    margin-left: 16px;
    width: 92%;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 4px;
  }

  .search-row {
    flex-direction: column;
    padding: 10px;
  }

  .search-row .s-header {
    font-size: 14px;
    font-weight: 500;
  }

  .search-row .select-group {
    margin-bottom: 10px;
    margin-right: 0px;
    /* Adjust margin for smaller screens */
  }

  .search-row select,
  .search-row input[type="text"] {
    /* width: 100%; Make inputs full width */
    margin-right: 0;
    /* Remove margin for smaller screens */
  }

  .logo img {
    margin-left: 20px;
    /* Adjust margin for smaller screens */
    height: 40px;
    /* Adjust height for smaller screens */
  }

  .auth-buttons {
    margin-right: 20px;
    /* Adjust margin for smaller screens */
  }

  .cta-button {
    padding: 10px;
    font-size: 14px;
    width: 257px;
    /* Match the width of the select inputs */
    font-family: "Playfair Display", serif;
    background-color: #83e1ed;
    color: #fff;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 10px;
    /* Adjust margin */
    font-weight: 500;
    text-decoration: none;
    text-align: center;

  }
}