.ot-section {
  background-color: #FCE5F0;
  padding: 40px 0;
  font-family: 'Playfair Display', serif;
}

.ot-section .container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ot-section .heading {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 100px;
}

.ot-section .heading p {
  font-size: 2.25rem;
  color: #000000;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  line-height: 47.99px;
}

.ot-section .cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 25px;
}

.ot-section .card {
  width: 39%;
  /* Adjusted width for two cards in a row */
  background-color: white;
  border-radius: 5px;
  border: 1px solid #F51E92;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  margin: 20px;
  /* Reduced margin for better spacing */
  padding: 20px;
}

.ot-section .card-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ot-section .card-title {
  margin: 10px 0;
  font-size: 24px;
  /* Increased font size */
  color: #000000;
  font-family: 'Playfair Display', serif;
}

.ot-section .card-description p {
  margin: 10px 0;
  font-size: 16px;
  /* Increased font size */
  line-height: 1.5;
  color: #000000;
  text-align: left;
  /* Left-align text for better readability */
}

.read-more {
  color: #F51E92;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.read-more:hover {
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .ot-section {

    padding: 0px;

  }

  .ot-section .heading {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 85px;
  }

  .ot-section .heading p {
    font-size: 1.5rem;
    /* Adjusted font size for extra small screens */
  }

  .ot-section .card {
    margin: 5px 0;
    /* Further adjusted margin for extra small screens */
    padding: 15px;
    /* Adjusted padding for extra small screens */
    width: 70%;
  }

  .ot-section .card-title {
    font-size: 18px;
    /* Adjusted font size for extra small screens */
    padding: 0px;
    margin: 0px;
  }

  .ot-section .card-description p {
    font-size: 15px;
    /* Adjusted font size for extra small screens */

  }

  .read-more {
    font-size: 15px;
    /* Adjusted font size for extra small screens */
  }
}