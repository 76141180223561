.find-story:first-child {
  margin-top: 10px;
  border-top: 1px solid #fd95ff !important;
  border-right: 1px solid #fd95ff;
  border-bottom: 1px solid #fd95ff;
}
.find-story:nth-child(2) {
  border-left: 1px solid #fd95ff;
}
.find-story:last-child {
  border-top: 1px solid #fd95ff;
  border-right: 1px solid #fd95ff;
  border-bottom: 1px solid #fd95ff;
}
.find-service {
  background: white;
  padding-top: 125px;
}
.find-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: white;
  max-width: 1300px;
  margin: 0 auto;
}
.find-stories {
  margin-top: 10px;
  padding-top: 20px;
}
.find-story {
  margin-left: 20px;
  display: flex;
  align-items: start;
  padding: 10px;
  border-radius: 2px;
}
.find-story img {
  margin-right: 10px; /* Margin for the icon */
  margin-left: 10px;
  position: relative;
}
.icon-wrapper .design-icon {
  position: absolute;
  left: 26px;
  top: 70px;
}
.icon-wrapper {
  position: relative;
}
.bullet-icon {
  width: 100px;
  height: 107px;
  margin-top: 5px;
  margin-top: 5px; /* Align the icon with the text */
}
.story-content {
  color: #50dada;
  display: flex;
  flex-direction: column;
  font-family: "Playfair Display", serif;
}
.success-storiess-column {
  width: 50%;
}
.story-content-cn {
  display: flex;
  flex-direction: column;
  font-family: "Playfair Display", serif;
  margin-left: 65px;
}
.story-content-cn h4 {
  align-self: end;
  margin-right: 5px;
}
.find-story h4 {
  margin: 0 1px 5px;
  font-size: 1.6rem;
  color: #50dada;
}
.find-story p {
  margin: 0;
  font-size: 1.3rem;
  color: #000000;
}
.dividers {
  border-left: 6px solid #39d3d3;
  min-height: 500px;
  margin: 0px 50px;
}
.title-columns {
  margin-top: 5%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 300px; /* Set the same height as the divider */
  font-family: "Playfair Display", serif;
}
.section-titles {
  font-size: 3rem;
  color: #333;
  text-align: start;
}
.section-titles .highlight {
  color: #f51e92;
}
@media only screen and (max-width: 767px) {
  .find-container {
    flex-direction: column;
    align-items: center; /* Center content on mobile */
  }
  .find-service {
    padding-top: 30px;
  }
  .title-columns {
    margin-left: 0;
    height: auto;
    order: 1; /* Change order for mobile */
  }
  .success-storiess-column {
    order: 2; /* Change order for mobile */
    width: 100%; /* Full width for mobile */
  }
  .dividers {
    display: none; /* Hide divider in mobile view */
  }
  .find-story {
    margin-left: 0; /* Adjust margin for mobile view */
    width: 99%; /* Adjust width for better mobile display */
    display: flex;
    justify-content: flex-end;
  }
  .find-story h4 {
    font-size: 1rem; /* Adjust font size for mobile */
  }
  .find-story p {
    font-size: 0.7rem; /* Adjust font size for mobile */
  }
  .bullet-icon {
    width: 56px; /* Adjust icon size for mobile */
    height: 63px;
  }
  .story-content-cn {
    margin-left: 0; /* Adjust margin for mobile */
    text-align: right;
  }
  .find-stories {
    padding: 20px 20px 0;
  }
  .icon-wrapper .design-icon {
    position: absolute;
    left: 17px;
    top: 46px;
    width: 23px;
  }
  .section-titles {
    font-size: 1.4rem; /* Adjust font size for mobile to fit in one line */
    text-align: center;
    text-wrap: nowrap;
    display: flex;
    gap: 8px;
    padding: 0 10px; /* Add padding for better alignment */
  }
}
