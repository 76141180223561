.pg-wrap {
  width: 960px;
  border: none;
  margin: 0 auto;
  padding: 120px 0;
  font: 12px arial;
  color: #72727d;
  min-height: 100vh;
}
.privacy-section .accordion-button {
  background-color: transparent !important;
  background: none !important;
  border: none !important;
  color: #72727d;
  font-size: 20px !important;
  width: auto !important;
}
.privacy-section .accordion-item {
  background-color: transparent !important;
  background: none !important;
  border: none !important;
}
.privacy-section .accordion-button::after {
  display: none !important;
}
.privacy-section .accordion-button:focus {
  box-shadow: none !important;
}
.privacy-section .accordion-body .ans_box {
  border: 1px solid #e5e5e5;
  padding: 15px;
  background: #f5f5f5;
  position: relative;
  border-radius: 3px;
  font-size: 16px ;
  line-height: 22px;
  position: relative;
}
.privacy-list {
  font-size: 16px;
  padding-bottom: 0;
  line-height: 24px;
}
.privacy-section .accordion-body .notch {
  background: url(https://img2.shaadi.com/imgs/icons/faq-notch.png) no-repeat
    left top;
  position: absolute;
  top: -9px;
  left: 30px;
  width: 13px;
  height: 9px;
}
.privacy-section .collapsed {
  color: #00bcd5 !important;
}
.privacy_policy_intro {
  padding: 15px 0 0;
  color: #6b7b84;
  font-size: 18px ;
}
.footer_bot_border {
    border-bottom: 1px solid #ddd;
}

.copyright_txt {
  padding: 12px 0;
}

.copyright_txt,
.copyright_lefttxt_rog {
  float: left;
  margin: 0 0 0 10px;
  text-align: left;
}

.numbers {
    color: #000 !important;
    font-size: 16px !important;
    margin-right: 5px !important;
}
.privacy-section {
    background: #FFEAF4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy-cover-image {
    position: relative;
    background: url('../../../../assets/images/about-cover.png') no-repeat center center;
    background-size: cover;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.privacy-cover-image h1 {
    color: hsl(0, 0%, 20%);
    font-size: 4rem;
    background: #FFEAF4;
    padding: 35px 150px;
    border-radius:37px 37px 0px 0px;
    margin: 3px;
    position: absolute;
    bottom: -7px;
    text-align: center;
    font-family: 'Playfair Display', serif;
}
@media only screen and (max-width: 767px) {
  .pg-wrap {
    width: 100%;
    font-size: 14px;
    padding: 23px;
    margin: 10px;
  }

  .privacy-section .accordion-button {
    font-size: 18px !important;
  }

  .privacy-section .accordion-body .ans_box {
    font-size: 14px;
    line-height: 20px;
    /* margin-top: -10px; */
    background: white;
  }

  .privacy-list {
    font-size: 14px;
    line-height: 20px;
  }

  .privacy_policy_intro {
    font-size: 17px;
    font-family: 'Playfair Display', serif;
    text-align: center;
  }

  .privacy-cover-image {
    position: relative;
    background: url('../../../../assets/images/about-cover.png') no-repeat center center;
    background-size: cover;
    height: 150px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

  .privacy-cover-image h1 {
    font-size: 1.2rem;
    padding: 13px 30px;
    border-radius: 5px;
  }
  .privacy-section .accordion-item .accordion-header{
   background-color: #FF0666;
   margin-bottom: 10px;
   border-radius: 10px;
   /* border-top-left-radius: 10px;
   border-top-right-radius: 10px; */
  }
  .privacy-section .accordion-item .accordion-header p{
    font-size: 16px;
    color: white;
    margin-bottom: 0px;
    font-family: 'Playfair Display', serif;
   }
  .privacy-section .accordion-item .accordion-body{
   padding: 0px;
   font-family: 'Playfair Display', serif;
   }
   .notch{
    display: none;
   }
   .privacy-section .accordion-item .accordion-header .numbers {
    color: white !important;
    font-size: 18px !important;
    /* margin-right: 5px !important; */
    margin-top: 1px;
}

  
}